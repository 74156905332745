import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Position, Menu, HTMLSelect, Slider } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import * as unit from 'polotno/utils/unit';
import { t } from 'polotno/utils/l10n';
import axios from 'axios';
import { useProject } from '../project';
import * as api from '../api';

export const SaveButton = observer(({ store }) => {
  const [saving, setSaving] = React.useState(false);
  const [quality, setQuality] = React.useState(1);
  const [fps, setFPS] = React.useState(10);
  const [type, setType] = React.useState('png');
  const project = useProject();
  const getName = () => {
    const texts = [];
    store.pages.forEach((p) => {
      p.children.forEach((c) => {
        if (c.type === 'text') {
          texts.push(c.text);
        }
      });
    });
    const allWords = texts.join(' ').split(' ');
    const words = allWords.slice(0, 6);
    return words.join(' ').replace(/\s/g, '-').toLowerCase() || 'polotno';
  };
  return (


    <Button
      icon="import"
      text="Sauvegarder"
      intent="primary"
      //loading={saving}
      onClick={async () => {


        // alert(project.id)
        setQuality(1);
     //   setSaving(true);
        const json = store.toJSON();
        console.log("Save Button")
        try
        {
         
          const res = await api.saveDesign({
            store: json,
            // preview,
            id: project.id,
            isPrivate: true,
            name: project.name,
            language: project.language,
            authToken: null,
          });
        }
        catch(e)
        {
          console.log("Errror")
        }
       


     //   setSaving(false);
      }}
    />

  );
});
