import localforage from 'localforage';
import axios from 'axios';
import { useProject } from './project';


const API = 'https://polotno-studio-api.vercel.app/api';
//const API2 = 'http://localhost:3004';

const API2 = 'https://mdw-b4f-pvalette.vercel.app';

export async function getDesignById({ id, authToken }) {

  if (id == 'local') id = "64c63aefae9f4714d49d6014"

  let url = `${API2}/api/b4food/technical-sheet-templates/${id}`
  let reponse = await axios.get(url);
  console.log(reponse.data)

  if (reponse.data.definition != undefined) {
    return {
      store: reponse.data.definition,
      name: reponse.data.name,
      language: reponse.data.language,
      id: reponse.data._id,
    };


  }

  return {}
  /*
  return {
    store: reponse.data.definition,
    name: reponse.data.name,
    id: reponse.data._id,
  };*/

}

export async function listDesigns({ accessToken }) {


  const req = await fetch(API + '/designs/list', {
    method: 'GET',
    headers: {
      Authorization: accessToken,
    },
  });
  return req.json();
}



export async function getUserSubscription({ accessToken }) {
  const req = await fetch(API + '/user/subscription', {
    method: 'GET',
    headers: {
      Authorization: accessToken,
    },
  });
  return req.json();
}

export async function cancelUserSubscription({ accessToken, id }) {
  const req = await fetch(API + '/user/cancel-subscription', {
    method: 'POST',
    headers: {
      Authorization: accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  });
  return req.json();
}


export async function saveDesign({
  store,
  preview,
  id,
  authToken,
  name,
  language,
  isPrivate,
}) {
  // if (id === 'local' || !authToken) {
  // localforage.setItem('polotno-state', store);

  let body = {
    definition: store
  }
  if (id == undefined) {
    let body =
    {
      "name": name,
      "code": "-",
      "tenant_id": "62f76a2df10a29ce8a81bdb5",
      "tags": [],
      "definition": {},
      "version": "3",
      "language": language
    }
    let url = `${API2}/api/b4food/technical-sheet-templates?version=3`
    let json = await axios.post(url, body);
    console.log(json)
    id = json.data.insertedId
  }
  else {
    body.name = name;
    console.log("Path Design ", name)

    //id="64de240bcc76bedff8d05d33"
    await axios.patch(`${API2}/api/b4food/technical-sheet-templates/${id}`, body)

  }


  return {
    id: id,
    status: 'saved',
  };


}

export async function deleteDesign({ id, authToken } = {}) {
  await localforage.clear();
  // const req = await fetch(`${API}/designs/delete`, {
  //   method: 'POST',
  //   headers: {
  //     Authorization: authToken,
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({ id }),
  // });
  // return await req.json();
}


export async function listProjects({ accessToken }) {
  let url = `${API2}/api/b4food/technical-sheet-templates?version=3`
  let json = await axios.get(url);

  return json.data.records
}

export async function createProject(name, { accessToken }) {
  let body =
  {
    "name": name,
    "code": "-",
    "tenant_id": "62f76a2df10a29ce8a81bdb5",
    "tags": [],
    "definition": {},
    "version": "3",
    "language": "en"
  }
  let url = `${API2}/api/b4food/technical-sheet-templates?version=3`
  let json = await axios.post(url, body);
  console.log(json)
  return json.data.records.insertedId
}

export async function loadTemplate({ accessToken, id }) {
  let url = `${API2}/api/b4food/technical-sheet-templates/${id}`
  let json = await axios.get(url);


  if (json.data.definition == undefined) {
    const pagesIds = window.store.pages.map((p) => p.id);

    window.store.deletePages(pagesIds);
    //https://papersizes.online/paper-size/a4/pixels/
    //window.store.setSize(2480, 3508);
    //window.store.setDPI(300)

    window.store.setSize(595, 842);
    //window.store.setDPI(72)

    //A4 300dpi
    window.store.addPage();
    //let project = useProject();
    // project.id = id;
    // project.save();
  }
  else {


    window.store.loadJSON(json.data.definition);

    //let project = useProject();
    // project.id = json.data._id;
    // project.id = json.data.name;
    //project.save();
  }
  return json.data;


}