import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import FaFileExport from '@meronex/icons/fa/FaFileExport';
import FaFileImport from '@meronex/icons/fa/FaFileImport';
import { downloadFile } from 'polotno/utils/download';
import { OpenTemplateModal } from './open-template-modal';

import axios from 'axios';
export const FileMenu = observer(({ store, project }) => {
  const inputRef = React.useRef();
  const [subModalOpen, toggleSubModal] = React.useState(false);

  const [faqOpened, toggleFaq] = React.useState(false);
  return (
    <>
      <Popover2
        content={
          <Menu>
            {/* <MenuDivider title={t('toolbar.layering')} /> */}
            <MenuItem
              icon="plus"
              text="Créer un nouveau modèle"
              onClick={() => {
                const ids = store.pages
                  .map((page) => page.children.map((child) => child.id))
                  .flat();
                const hasObjects = ids?.length;
                if (hasObjects) {
                  if (!window.confirm('Remove all content for a new design?')) {
                    return;
                  }
                }
                const pagesIds = store.pages.map((p) => p.id);
                store.deletePages(pagesIds);
                //https://papersizes.online/paper-size/a4/pixels/
                //store.setSize(2480, 3508); //A4 300dpi
                store.setSize(595, 842);
              //  store.setDPI(72)
                store.addPage();
                project.id = null;
                project.name = 'Nouveau template'
                project.save();
              }}
            />
             
          
             
            <MenuDivider />
            <MenuItem
              // icon={<FaFileImport />}
              icon="folder-open"
              text="Ouvrir"
              onClick={async () => {
                toggleSubModal(true);
              }}
            />
            <MenuItem
              // icon={<FaFileExport />}
              icon="floppy-disk"
              text="Dupliquer 2"
              onClick={async () => {
                const json = store.toJSON();
               
                const ids = store.pages
                .map((page) => page.children.map((child) => child.id))
                .flat();
              const hasObjects = ids?.length;
              if (hasObjects) {
                if (!window.confirm('Remove all content for a new design?')) {
                  return;
                }
              }
              const pagesIds = store.pages.map((p) => p.id);
              store.deletePages(pagesIds);
              //https://papersizes.online/paper-size/a4/pixels/
              //store.setSize(2480, 3508); //A4 300dpi
              store.setSize(595, 842);
            //  store.setDPI(72)
              store.loadJSON(json);
              project.id = null;
              project.name = 'Nouveau template'
              project.save();

              //  await axios.patch('http://localhost:3004/api/b4food/technical-sheet-templates/' + project.id, body)

                //         const url =
                //           'data:text/json;base64,' +
                //           window.btoa(
                //             unescape(encodeURIComponent(JSON.stringify(json)))
                //           );
                //alert(url)
                //  downloadFile(url, 'polotno.json');
              }}
            />

            <MenuDivider />
            <MenuItem
              text="About"
              icon="info-sign"
              onClick={() => {
                toggleFaq(true);
              }}
            />

          </Menu>
        }
        position={Position.BOTTOM_RIGHT}
      >
        <Button minimal text="File" />
      </Popover2>
      <OpenTemplateModal
        store={store}
        isOpen={subModalOpen}
        onClose={() => toggleSubModal(false)}
      />
      <input
        type="file"
        id="load-project"
        accept=".json"
        ref={inputRef}
        style={{ width: '180px', display: 'none' }}
        onChange={(e) => {
          var input = e.target;

          if (!input.files.length) {
            return;
          }

          var reader = new FileReader();
          reader.onloadend = function () {
            var text = reader.result;
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {
              alert('Can not load the project.');
            }

            if (json) {
              store.loadJSON(json);
              input.value = '';
            }
          };
          reader.onerror = function () {
            alert('Can not load the project.');
          };
          reader.readAsText(input.files[0]);
        }}
      />
      <Dialog
        icon="info-sign"
        onClose={() => toggleFaq(false)}
        title="B4Food Studio"
        isOpen={faqOpened}
        style={{
          width: '80%',
          maxWidth: '700px',
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <h2>B4Food Studio</h2>
          <p>
            <strong>B4Food Studio</strong> - permet de définir les modèles pour la génération des Fiches Techniques.
          </p>
        
       
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => toggleFaq(false)}>Fermer</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});
